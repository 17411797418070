const routes = [
    getDashboardRoute(),
    getLoginRoute(),
    getLogoutRoute(),
]

function getDashboardRoute() {
    return {
        name: 'publicDashboard',
        path: '/',
        meta: {
            requiresAuth: true,
            loginPage: false,
        },
        component: () =>
            import ('../views/Home.vue')
    }
}

function getLoginRoute() {
    return {
        name: 'publicLogin',
        path: '/p/login',
        meta: {
            requiresAuth: false,
            loginPage: true,
        },
        component: () =>
            import ('../views/Login.vue')
    }
}

function getLogoutRoute() {
    return {
        name: 'publicLogout',
        path: '/p/logout',
        meta: {
            requiresAuth: false,
            loginPage: true,
        },
        component: () =>
            import ('../views/Logout.vue')
    }
}

export default routes