<template>
<v-app>
    <v-app-bar app color="primary" dark>
        <div class="d-flex align-center">
            <span>ON</span>
            <span class="font-weight-light">BOARD</span>
        </div>

        <v-spacer></v-spacer>

        <v-btn @click="logout" target="_blank" color="red darken-3" text>
            <span class="mr-2">Logout</span>
            <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
    </v-app-bar>

    <v-main>
        <router-view />
    </v-main>
</v-app>
</template>

<script>
export default {
    name: 'App',

    components: {},

    data: () => ({
        //
    }),
    methods: {
        logout() {
            confirm('Are you sure you want to logout in CAS ?') && this.$router.push({ name: 'publicLogout' })
        },
    },
};
</script>
