import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicRoute from './public'
// import store from '../store'
import axios from 'axios'
import config from '../../public/config.json'

Vue.use(VueRouter)

let routes = []

routes = routes.concat(PublicRoute)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!localStorage.getItem('token')) {
            window.location = '/p/login';
        } else {
            axios.get(config.apiEndpoint + '/auth/validate/')
                .then(response => {
                    if (response.data.data.email == "") {
                        window.location = '/p/login';
                    } else {
                        if (to.matched.some(record => record.meta.requiresAdmin)) {
                            if (response.data.data.scope.includes("admin")) {
                                next()
                            } else {
                                next("/")
                            }
                        } else {
                            next()
                        }
                    }
                })
                .catch(() => {
                    localStorage.removeItem("token");
                    window.location = '/p/login';
                })
        }
    } else {
        next()
    }
})

export default router