import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSession from 'vue-session'
import axios from 'axios'
import vueScrollto from 'vue-scrollto'
import vuetify from './plugins/vuetify';


Vue.config.productionTip = false

axios.defaults.headers.common['X-AUTH-TYPE'] = "external"

if (localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
}

var options = {
    persist: true
};

Vue.use(axios);
Vue.use(vueScrollto)

Vue.use(VueSession, options);
Vue.prototype.$http = axios;

Vue.mixin({
    methods: {
        getToken: function() {
            return "3jkh4fhg5f74g8dgf5d34h5d3hgd78s5w8"
        },
        setJwt: function() {
            if (localStorage.getItem('token')) {
                axios.defaults.headers.common['Authorization'] = localStorage.getItem('token')
                return true;
            } else {
                return false;
            }
        }
    },
});

fetch(process.env.BASE_URL + "config.json")
    .then((data) => {
        data.json().then((config) => {
            Vue.prototype.$config = config
            new Vue({
                router,
                store,
                vuetify,
                render: (h) => h(App)
            }).$mount("#app")
        })
    })